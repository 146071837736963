.list {
  display: grid;
  grid-gap: 12px;
}

.notification {
  padding: 6px;
  background-color: var(--white);
  box-shadow: var(--card-shadow);
  border-radius: 4px;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-gap: 12px;
  text-align: left;
  text-decoration: none;
}

.icon {
  background-color: var(--faint-blue);
  border-radius: 3px;
  padding: 6px;
}

.notification:active {
  filter: none;
}

.notification time {
  color: var(--grey);
}
