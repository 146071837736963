.container {
  padding: 0 4px;
  padding-left: max(4px, env(safe-area-inset-left));
  padding-right: max(4px, env(safe-area-inset-right));
  position: relative;
  -webkit-tap-highlight-color: transparent;
  background-color: var(--navy);
  color: var(--link-color);
  z-index: 11;
  display: grid;
  user-select: none;
  grid-row: 4;
  position: sticky;
  bottom: 0;
  animation: fade-in 200ms ease-out both;

  --link-inactive-color: var(--grey-2);
  --link-color: var(--white);
  --link-active-color: var(--mint);
  --badge-outline-color: var(--navy);
}

.list {
  display: flex;
  justify-content: center;
}

.item {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 104px;
}

.link {
  font-size: 11px;
  line-height: 16px;
  padding: 10px 0;
  padding-bottom: max(10px, env(safe-area-inset-bottom));
  color: var(--link-inactive-color);
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

[lang^="de"] .link,
[lang^="fi"] .link {
  hyphens: auto;
}

.link,
.link > :global(.icon) {
  transition: color 91ms ease-out;
}

.link:active,
.active {
  color: var(--link-active-color);
  filter: none;
}

.link:active > :global(.icon),
.active > :global(.icon) {
  color: var(--link-active-color);
}

.active {
  font-weight: bold;
}

.icon-and-badge {
  position: relative;
  margin: 0 0 2px 0;
}

.icon-and-badge :global(.icon) {
  --size: 24px;
}

.badge {
  font-size: 12px;
  line-height: 18px;
  width: 18px;
  height: 18px;
  background-color: var(--orange);
  color: var(--navy);
  border: 1px solid var(--badge-outline-color);
  border-radius: 50%;
  position: absolute;
  left: 41.4%;
  bottom: 30%;
  text-align: center;
}

.badge:empty {
  width: 12px;
  height: 12px;
  bottom: 50%;
  left: 50%;
}

@media (min-width: 912px) {
  .container {
    display: grid;
    place-content: center center;
    padding: 0;
    grid-row: 1;
    grid-column: 1;
    bottom: auto;
    top: 0;
    background: none;
    pointer-events: none;
  }

  .connected {
    --link-inactive-color: #215543;
    --link-color: var(--navy);
    --link-active-color: var(--navy);
    --badge-outline-color: currentcolor;
  }

  .list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .item {
    max-width: none;
    flex-basis: auto;
    flex-grow: auto;
  }

  .link {
    flex-direction: row;
    font-size: 14px;
    line-height: 24px;
    padding: 0;
    text-align: unset;
    pointer-events: all;
  }

  .link:active,
  .active {
    color: var(--link-color);
  }

  .item + .item {
    margin-left: 20px;
  }

  .icon-and-badge {
    margin: 0 8px 0 0;
  }

  .icon-and-badge :global(.icon) {
    --size: 16px;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
