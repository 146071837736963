.spinner {
  animation: spin 1s ease-in-out infinite;
  place-self: center;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
