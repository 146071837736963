.container {
  height: var(--header-height);
  box-sizing: content-box;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  background-color: var(--navy);
  user-select: none;
  color: var(--white);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  z-index: 11;
  position: sticky;
  top: 0;
  grid-row: 1;
  grid-column: 1/-1;
  display: grid;
  padding-top: env(safe-area-inset-top);
}

.container > * {
  padding: 0 var(--outer-padding);
  padding-left: max(var(--outer-padding), env(safe-area-inset-left));
  padding-right: max(var(--outer-padding), env(safe-area-inset-right));
}

@media (min-width: 912px) {
  .container {
    grid-row: 1;
    grid-column: 1;
  }
}

.header {
  grid-area: 1/1/1/1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: fade-in 200ms ease-out both;
}

.header h1 {
  position: relative;
}

.header img {
  height: 26px;
  display: block;
}

.button {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  --color: inherit;
}

.button > :global(.icon) {
  margin-right: 3px;
  color: var(--white);
}

.button,
.button > svg {
  transition: color 91ms ease-out;
}

.button:active {
  --color: var(--mint);
  filter: none;
}

.button:active > svg {
  --color: var(--mint);
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
