:global(body) {
  overflow-anchor: none;
}

:global(html),
:global(body),
:global(#root) {
  display: flex;
  width: 100%;
}

:global(#root) {
  flex-direction: column;
}

.container {
  --outer-padding: 8px;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  flex-grow: 1;
}

.main {
  display: grid;
  grid-template-rows: min-content min-content 1fr;
}

:global(.spacing) {
  display: grid;
  grid-gap: var(--gap);
}

:global(.content),
:global(.ptr),
:global(.ptr__children) {
  --gap-x: 0;
  --gap: 16px;
  --gap-y: var(--gap);
  --max-width: 640px;
  grid-gap: var(--gap-y) var(--gap-x);

  --padding-top: 16px;
  --padding-bottom: 48px;
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);

  display: grid;
  grid-template-columns:
    minmax(var(--outer-padding), 1fr)
    minmax(0, var(--max-width))
    minmax(var(--outer-padding), 1fr);
  grid-template-columns:
    minmax(max(var(--outer-padding), env(safe-area-inset-left)), 1fr)
    minmax(0, var(--max-width))
    minmax(max(var(--outer-padding), env(safe-area-inset-right)), 1fr);
  place-content: start stretch;
  place-items: start stretch;
  grid-column: 1/-1;
  overflow: visible !important;
}

:global(.content),
:global(.ptr) {
  animation: entry 124ms ease-out;
}

/* 
  Chrome (at least 89.0.4389.82) and Edge in mobile mode have a layout bug,
  where an element overflowing outside the viewport to the right
  causes any `position: sticky; bottom: 0;` elements to jump downwards
  equal to 2x (I think) the amount of overflow.
  
  Our sub-navigation view transition animations trigger this for our 
  navigation bar. So I added this hack to crop out the overflow.

  As of Jan 27th 2022 this still hasn't been fixed.
*/
:global(.chrome-layout-fix) {
  overflow: hidden !important;
  animation: none;
  --padding-top: 0;
}

@keyframes entry {
  0% {
    opacity: 0;
    transform: translateY(-24px);
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: translateY(0);
  }
}

:global(.from-left) {
  animation: from-left 200ms ease-out;
}
:global(.from-right) {
  animation: from-right 200ms ease-out;
}

@keyframes from-left {
  0% {
    opacity: 0;
    transform: translateX(-48px);
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes from-right {
  0% {
    opacity: 0;
    transform: translateX(48px);
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}

:global(.ptr__children) {
  --padding-top: 0;
}

@media (max-width: 639px) {
  :global(.no-padding-top-when-narrow) {
    --padding-top: 0;
  }
}

/*
  Create "padding" on the sides as grid columns,
  but allow extending elements on top of the padding with the global .full-width class.
*/

:global(.content) > *,
:global(.ptr) > *,
:global(.ptr__children) > * {
  grid-column: 2/3;
}

:global(.content) > :global(.full-width),
:global(.ptr__children) > :global(.full-width),
:global(.content) > :global(.always-full-width),
:global(.ptr__children) > :global(.always-full-width) {
  grid-column: 1/4;
}

@media (min-width: 370px) {
  .container {
    --outer-padding: 16px;
  }
}

@media (min-width: 640px) {
  .container {
    grid-template-rows: min-content 1fr min-content;
  }

  :global(.content) > :global(.full-width),
  :global(.ptr__children) > :global(.full-width) {
    grid-column: 2/3;
  }

  .content {
    --padding-bottom: 72px;
  }
}

:global(.content) :global(.content),
:global(.content) :global(.ptr__children) {
  grid-column: 1/-1;
  will-change: unset;
}

