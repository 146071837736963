.container {
  width: auto !important;
  animation: entry 180ms ease-out;
  margin: auto;
}

.text {
  display: grid;
  grid-gap: 8px;
}

@keyframes entry {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
