.button {
  --color: var(--blue);
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  display: inline-flex;
  vertical-align: middle;
  text-decoration: none;
  color: var(--color);
  transition: 62ms ease-out;
  transition-property: color, background-color, border-color, filter;
  cursor: pointer;
  text-align: left;
}

/* Overrides default <a> styling */
.button {
  text-decoration: none;
}
.button:active,
.button:hover {
  filter: none;
}

/* Button variants */

.underlined {
  --color: inherit;
  opacity: 0.764;
  text-decoration: underline;
}

.link:active,
.link.active,
.underlined:active,
.underlined.active {
  filter: contrast(38.2%);
}

.primary,
.secondary,
.tertiary {
  --padding: 10px 32px;
  --border-radius: 8px;
  --border-color: transparent;
  border: 1px solid var(--border-color);
  padding: var(--padding);
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  -webkit-tap-highlight-color: transparent;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.primary {
  font-weight: 700;
  --background-color: var(--blue);
  --color: var(--white);
  --shadow-color: rgba(85, 96, 255, 0.35);
  box-shadow: 0px 1px 2px var(--shadow-color);
}

.secondary {
  font-weight: 600;
  --background-color: var(--faint-blue);
}

.tertiary {
  font-weight: 600;
  --background-color: transparent;
  --active-background-color: var(--faint-blue);
  --active-color: var(--blue);
  --border-color: var(--color);
  --shadow-color: transparent;
  --padding: 11px 31px;
  box-shadow: 0px 1px 2px var(--shadow-color);
}

/* Active states */

.primary:active:not(:disabled),
.primary.active {
  filter: contrast(223.6%) saturate(50%);
}
.secondary:active:not(:disabled),
.secondary.active {
  --border-color: var(--color);
  border-color: var(--border-color);
}
.tertiary:active:not(:disabled),
.tertiary.active {
  --background-color: var(--active-background-color) !important;
  --color: var(--active-color) !important;
}

/* Disabled states */

.button:disabled {
  cursor: not-allowed;
  font-weight: normal;
  box-shadow: none;
}

.link:disabled,
.tertiary:disabled {
  filter: opacity(50%) grayscale(100%);
}

.primary:disabled,
.secondary:disabled {
  --color: var(--grey-2);
  --background-color: var(--grey-3);
}

.secondary:disabled {
  --border-color: var(--grey-3);
}

/* Special cases */

.small {
  --padding: 6px 11px;
  --border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
}

.round {
  border-radius: 22px;
}

.small.round {
  border-radius: 14px;
}

.arrow::after {
  content: "";
  display: inline-block;
  height: 0;
  width: 0;
  border: 0.375em solid transparent;
  border-left-width: 0.5em;
  border-right-width: 0;
  border-left-color: currentcolor;
  margin-left: 0.75em;
  vertical-align: baseline;
}

.icon-only {
  --size: 48px;
  padding: 0;
  min-width: var(--size);
  height: var(--size);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.icon-only.round {
  border-radius: 50%;
}

.icon-only.small {
  --size: 30px;
}
