.container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
}

.ripple {
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--mint);
  width: 96px;
  height: 96px;
  border-radius: 50%;
  will-change: transform, opacity;
  opacity: 0;
}
