.container {
  min-height: var(--sub-navigation-height);
  background-color: var(--navy);
  color: var(--white);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.content {
  animation: fade-in 200ms ease-out both;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
