.container {
  background-color: var(--mint);
  color: var(--navy);
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: right;
  grid-area: 1/1/1/1;
  pointer-events: none;
  z-index: 12;
}

.container > * {
  pointer-events: all;
}

.status {
  text-align: left;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 6px;
  align-items: center;
}

.status > * {
  font-size: 11px;
  line-height: 14px;
}

.status :global(.icon) {
  position: relative;
  top: 2px;
}
