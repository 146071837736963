.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--grey-4);
  overflow: auto;
  overscroll-behavior: contain;
  z-index: 100;
  will-change: transform;
  animation: entry 200ms ease-out both;
}

:global(.is-native) .modal {
  overscroll-behavior: none;
}

@keyframes entry {
  0% {
    transform: translateY(-14.6%);
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: translateY(0);
  }
}

.modal:focus {
  outline: none;
}

.modal :global(.content) {
  padding-top: 0;
  margin: auto;
  animation: none !important;
}

/* Special case modals */
.welcome {
  color: var(--white);
  background: var(--navy) url("../images/photos/welcome@720.jpg") center top
    no-repeat;
  background-size: 100% auto;
  padding-top: var(--outer-padding);
  display: flex;
}

@media (min-width: 720px) {
  .welcome {
    background-image: linear-gradient(rgba(2, 37, 72, 0) 0%, var(--navy)),
      url("../images/photos/welcome@1080.jpg");
  }
}

.video-contest,
.video,
.video-contest-submit {
  background-color: var(--white);
}

.change-league > :global(.content) {
  min-height: 100%;
  padding: 0;
  --gap: 0;
  grid-template-rows: auto 1fr;
}

.header {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: var(--white);
}

.transparent {
  background-color: transparent;
  position: static;
}

.remove-top-gap {
  margin-bottom: calc(var(--gap-y) * -1);
}

.dark {
  color: var(--white);
  background-color: var(--navy);
}

.header-inner {
  max-width: var(--max-width);
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 36px;
  place-items: center start;
  padding: var(--outer-padding);
  padding-top: max(var(--outer-padding), env(safe-area-inset-top));
  box-sizing: content-box;
  margin: 0 auto;
}

.dark .header-inner,
.has-back .header-inner {
  grid-template-columns: 36px 1fr 36px;
  place-items: center;
  text-align: center;
}

.header-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.header-content > *:not(:last-child) {
  margin-right: 8px;
}

.dark .header-content,
.has-back .header-content {
  grid-column: 2;
}

.dark .header-content {
  font-weight: 600;
}
