:root {
  /* Colors */
  --white: #ffffff;
  --black: #252525;
  --grey: #5b6368;
  --grey-1: var(--grey);
  --grey-2: #a3abb0;
  --grey-3: #e1e5ea;
  --grey-4: #eff3f9;

  --dark-navy: #022548;
  --navy: var(--dark-navy);
  --neon-blue: #5560ff;
  --faint-blue: rgba(85, 96, 255, 0.1);
  --blue: var(--neon-blue);
  --gold: #ffd600;
  --yellow: var(--gold);
  --orange: #ff9a00;
  --mint: #00e4ab;
  --faint-mint: rgba(0, 228, 171, 0.2);

  --red: #b22525;
  --faint-red: rgb(251, 234, 234);
  --semi-faint-red: rgb(230, 137, 137);
  --green: #14a38b;

  --open-sans: "Open Sans", system-ui, -apple-system, Roboto, Cantarell,
    sans-serif, "Segoe UI Emoji", "Noto Color Emoji";
  --squada-one: "Squada One", Menlo, Consolas, Monaco, Liberation Mono,
    Lucida Console, monospace, "Segoe UI Emoji", "Noto Color Emoji";

  --card-shadow: 0px 1px 2px 0px rgb(37, 37, 37, 0.2);
}

html {
  color: var(--black);
  -webkit-tap-highlight-color: rgba(85, 96, 255, 0.382);
}

::selection {
  background-color: rgb(0, 228, 171, 0.5);
}

html {
  font-family: var(--open-sans);
}

.squada-one {
  font-family: var(--squada-one);
}

code,
kbd,
pre,
samp {
  font-family:
    /* macOS 10.10+ */ Menlo, /* Windows 6+ */ Consolas,
    /* Android 4+ */ Roboto Mono, /* Ubuntu 10.10+ */ Ubuntu Monospace,
    /* KDE Plasma 5+ */ Noto Mono, /* KDE Plasma 4+ */ Oxygen Mono,
    /* Linux/OpenOffice fallback */ Liberation Mono, /* fallback */ monospace;
}

.semibold {
  font-weight: 600;
}

.title {
  --size: 56px;
  font-family: var(--squada-one);
  font-size: var(--size);
  line-height: var(--size);
}

[lang^="de"] .title,
[lang^="fi"] .title {
  hyphens: auto;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.bold {
  font-weight: 700;
}

a {
  --color: inherit;
  color: var(--color);
  text-decoration: underline;
}

/* See also Button.module.css */
a:active {
  filter: contrast(38.2%);
}

hr {
  border: none;
  border-top: 1px solid var(--grey-2);
  opacity: 0.382;
}

/* Type presets and helpers */

html {
  font-size: 16px;
  line-height: 150%;
}

.h1 {
  font-size: 24px;
  line-height: 28px;
}

.h2,
.large {
  font-size: 18px;
  line-height: 24px;
}

.h3 {
  font-size: 16px;
  line-height: 24px;
}

small,
.small,
.h4 {
  font-size: 14px;
  line-height: 20px;
}

.tiny,
.h5 {
  font-size: 12px;
  line-height: 16px;
}

.all-caps {
  text-transform: uppercase;
}

.capitalize-first::first-letter {
  text-transform: uppercase;
}

/* 
  Effectively disable animations and transitions for:
  - people who have requested reduced animation in their OS/browser settings
  - screens that refresh too slowly to animate anything well.
  
  This is not a perfect solution by any means, but it prevents our animations from
  making people with serious vestibular issues sick.
  
  https://css-tricks.com/revisiting-prefers-reduced-motion-the-reduced-motion-media-query/
*/

@media screen and (prefers-reduced-motion: reduce), (update: slow) {
  * {
    animation-duration: 0.00001ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.00001ms !important;
  }
}

/* Hide focus styling unless using a pointerless device, like a keyboard. */

:focus:not(:focus-visible) {
  outline: none;
}

.icon {
  --size: 1em;
  width: var(--size);
  height: var(--size);
  line-height: var(--size);
  display: inline-block;
  vertical-align: middle;
}

.visually-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.dialog {
  --gap: 32px;
  display: grid;
  grid-gap: var(--gap);
  max-width: 480px;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  place-self: center;
  min-height: 76.4vh;
  margin-left: auto;
  margin-right: auto;
}

.card {
  background: var(--white);
  box-shadow: var(--card-shadow);
  border-radius: 8px;
  padding: 16px;
  display: grid;
  grid-gap: 12px;
}

/* Hack */
.react-cookie-banner,
.smartbanner {
  z-index: 0 !important;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
